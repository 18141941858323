// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "y_sQ d_gR d_cv d_dt";
export var quoteParagraphCenter = "y_gS d_gS d_cv d_dv";
export var quoteParagraphRight = "y_gT d_gT d_cv d_dw";
export var quoteRowLeft = "y_sR d_bF";
export var quoteRowCenter = "y_sS d_bC";
export var quoteRowRight = "y_sT d_bG";
export var quoteWrapper = "y_gP d_gP d_v d_cC d_by d_bN d_bC";
export var quoteContentWrapper = "y_gQ d_gQ";
export var quoteExceptionSmall = "y_sV z_sV";
export var quoteExceptionNormal = "y_sW z_sW";
export var quoteExceptionLarge = "y_sX z_sX";
export var quoteAuthorExceptionSmall = "y_sY z_sY";
export var quoteAuthorExceptionNormal = "y_sZ z_sZ";
export var quoteAuthorExceptionLarge = "y_s0 z_s0";