// extracted by mini-css-extract-plugin
export var noMargin = "l_pK d_cb";
export var carouselCol = "l_pL d_cC";
export var carouselWrapper = "l_k6 d_k6 d_by d_bC d_bN d_v d_G";
export var carouselImageWrapper = "l_k7 d_k7 d_G d_v";
export var carouselContainer = "l_pM d_cC";
export var carouselContainerFull = "l_pN d_dS";
export var carouselImg = "l_mN d_k8 d_v d_G d_bQ";
export var imageTextWrapperLeft = "l_k1 d_k1 d_k0 d_Z d_bS";
export var imageTextWrapperCenter = "l_k2 d_k2 d_k0 d_Z d_bS";
export var imageTextWrapperRight = "l_k3 d_k3 d_k0 d_Z d_bS";
export var imageTextWrapperHidden = "l_kZ d_kZ";
export var imageAltSmall = "l_pP d_k4";
export var imageAltNormal = "l_pQ d_k4";
export var imageAltLarge = "l_pR d_k4";
export var imageTextSmall = "l_pS d_k5";
export var imageTextNormal = "l_pT d_k5";
export var imageTextLarge = "l_pV d_k5";