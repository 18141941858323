// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rW d_gt d_cr";
export var heroHeaderCenter = "s_gv d_gv d_cr d_dv";
export var heroHeaderRight = "s_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "s_rX d_gq d_cv";
export var heroParagraphCenter = "s_gr d_gr d_cv d_dv";
export var heroParagraphRight = "s_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "s_rY d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "s_rZ d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "s_r0 d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "s_r1 d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "s_r2 d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "s_r3 z_r3";
export var heroExceptionNormal = "s_r4 z_r4";
export var heroExceptionLarge = "s_r5 z_r5";
export var Title1Small = "s_r6 z_r6 z_s1 z_s2";
export var Title1Normal = "s_r7 z_r7 z_s1 z_s3";
export var Title1Large = "s_r8 z_r8 z_s1 z_s4";
export var BodySmall = "s_r9 z_r9 z_s1 z_tk";
export var BodyNormal = "s_sb z_sb z_s1 z_tl";
export var BodyLarge = "s_sc z_sc z_s1 z_tm";