// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "B_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "B_fQ d_fQ d_by d_bH";
export var menuDesign6 = "B_t2 d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "B_t3 d_fL d_by d_dv d_bN d_bH";
export var menuRight = "B_t4 d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "B_t5 d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "B_t6 d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "B_qf d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "B_qq d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "B_t7";
export var navbarItem = "B_qg d_bw";
export var navbarLogoItemWrapper = "B_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "B_t8 d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "B_t9 d_gc d_bx d_Y d_br";
export var burgerToggle = "B_vb d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "B_vc d_gc d_bx d_Y d_br";
export var burgerInput = "B_vd d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "B_vf d_f2 d_v d_G";
export var burgerLine = "B_vg d_f0";
export var burgerMenuDesign6 = "B_vh d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "B_vj d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "B_vk d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "B_vl d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "B_vm d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "B_vn d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "B_vp d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "B_vq d_bB d_bN";
export var staticBurger = "B_vr";
export var menu = "B_vs";
export var navbarDividedLogo = "B_vt";
export var nav = "B_vv";