// extracted by mini-css-extract-plugin
export var carouselContainer = "D_pM d_v d_G d_bd d_Y";
export var carouselContainerCards = "D_vL D_pM d_v d_G d_bd d_Y";
export var carouselContainerSides = "D_vM d_v d_G d_Y";
export var prevCarouselItem = "D_vN d_v d_G d_Z d_j";
export var prevCarouselItemL = "D_vP D_vN d_v d_G d_Z d_j";
export var moveInFromLeft = "D_vQ";
export var prevCarouselItemR = "D_vR D_vN d_v d_G d_Z d_j";
export var moveInFromRight = "D_vS";
export var selectedCarouselItem = "D_vT d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "D_vV D_vT d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "D_vW D_vT d_v d_G d_Y d_bd";
export var nextCarouselItem = "D_vX d_v d_G d_Z d_j";
export var nextCarouselItemL = "D_vY D_vX d_v d_G d_Z d_j";
export var nextCarouselItemR = "D_vZ D_vX d_v d_G d_Z d_j";
export var arrowContainer = "D_v0 d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "D_v1 D_v0 d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "D_v2 D_v1 D_v0 d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "D_v3 D_v0 d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "D_v4";
export var nextArrowContainerHidden = "D_v5 D_v3 D_v0 d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "D_kG d_Z";
export var prevArrow = "D_v6 D_kG d_Z";
export var nextArrow = "D_v7 D_kG d_Z";
export var carouselIndicatorContainer = "D_v8 d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "D_v9 d_v d_by d_bD";
export var carouselText = "D_wb d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "D_wc D_wb d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "D_wd d_b6";
export var carouselIndicator = "D_wf D_wd d_b6";
export var carouselIndicatorSelected = "D_wg D_wd d_b6";
export var arrowsContainerTopRight = "D_wh d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "D_wj d_Z d_bk d_bB";
export var arrowsContainerSides = "D_wk d_Z d_bk d_bB";
export var smallArrowsBase = "D_wl d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "D_wm D_wl d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "D_wn D_wm D_wl d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "D_wp D_wl d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "D_wq D_wp D_wl d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "D_pC";
export var iconSmall = "D_wr";
export var multipleWrapper = "D_ws d_bB d_bD d_bd";
export var multipleImage = "D_wt d_bB";
export var sidesPrevContainer = "D_wv D_wm D_wl d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "D_ww D_wm D_wl d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";