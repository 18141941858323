// extracted by mini-css-extract-plugin
export var alignLeft = "w_pX d_fn d_bF d_dt";
export var alignCenter = "w_bN d_fp d_bC d_dv";
export var alignRight = "w_pY d_fq d_bG d_dw";
export var menuContainer = "w_sr d_dV";
export var menuContainerFull = "w_ss d_dS";
export var menuMainHeader = "w_jB d_jB d_v d_c2";
export var menuComponentWrapper = "w_jF d_jF d_ct";
export var menuComponentWrapperDesign2 = "w_jG d_jG d_cx";
export var menuComponentText = "w_jC d_jC d_cm";
export var menuComponentTextDesign2 = "w_jD d_jD d_cr";
export var menuImageWrapperDesign2 = "w_jH d_jH d_v d_Y";