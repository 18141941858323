// extracted by mini-css-extract-plugin
export var alignLeft = "x_pX d_fn d_bF d_dt";
export var alignCenter = "x_bN d_fp d_bC d_dv";
export var alignRight = "x_pY d_fq d_bG d_dw";
export var productsContainer = "x_st d_dV";
export var productsTextWrapper = "x_sv d_Z d_8 d_v d_cF";
export var productsImageElement = "x_lF";
export var productsModalWrapper = "x_sw d_by d_bK";
export var productsModalWrapperDesign3 = "x_l1 d_l1 d_by d_bL d_bS d_bC d_cF d_bd";
export var modalLeftColumn = "x_sx d_q d_G";
export var modalLeftColumnDesign2 = "x_sy d_q d_G";
export var modalCloseIcon = "x_sz d_dz";
export var modalRightColumn = "x_sB d_q d_G d_bS d_by d_bL d_cF";
export var productsComponentsWrapperLeft = "x_lY d_lY d_x d_v d_ct d_dz";
export var modalCloseButton = "x_sC d_v d_by d_bK d_bN d_bG";
export var modalCloseButtonDesign3 = "x_sD d_v d_by d_bK d_bN d_bG";
export var modalTextWrapperDesign3 = "x_sF d_G d_by d_bL d_bN";
export var modalTextWrapper = "x_sG d_by d_bL d_bN";
export var modalCarouselWrapper = "x_sH d_G d_by d_bK d_bC";
export var carouselWrapper = "x_k6 d_G d_by d_bK d_bC";
export var productsCarouselImageWrapper = "x_sJ d_lS d_G d_v";
export var productsCarouselImageWrapperSides = "x_sK d_G";
export var productsCarouselImageWrapperDesign3 = "x_lT d_lT d_bd";
export var productsCarouselWrapper = "x_lV d_lV d_by d_bC d_bN d_v d_G";
export var productsCarouselWrapperDesign3 = "x_lW d_lW d_by d_bC d_bN d_v";
export var productsCarouselImg = "x_mT d_lX d_v d_G d_bQ";
export var productsImageModalWrapper = "x_lH";
export var productsImageElementDesign3 = "x_lJ d_lJ d_hG d_v d_G d_bQ";
export var productsContainerFull = "x_sL d_dS";
export var productsMainHeader = "x_l3 d_l3 d_l2 d_v d_dv d_cs";
export var productsMainSubtitle = "x_l4 d_l4 d_l2 d_v d_dv d_cx";
export var productsComponentHeading = "x_l5 d_l5";
export var productsComponentParagraph = "x_l6 d_l6";
export var productsCard = "x_lK d_lK d_bc d_Z d_7 d_6 d_3 d_8 d_bx d_x d_V d_G";
export var productsCardWrapper = "x_lL d_lL d_bx d_v d_Y";
export var productsCardWrapperDesign2 = "x_lM d_lM d_v d_G d_by d_bL d_bS";
export var productsTextBlockDesign2Wrapper = "x_sM d_cF";
export var productsTextBlockDesign3Wrapper = "x_lN d_lN d_v d_by d_bL d_bC d_bN d_cF d_fm d_Z";
export var productsTextBlockDesign2 = "x_lP d_lP d_by d_bL d_G";
export var productsTextBlockDesign3 = "x_lQ d_lQ d_by d_bL d_bN d_bk";
export var productsComponentsWrapperDesign2 = "x_lZ d_lZ d_x d_v d_by d_bL d_ct d_bS";
export var productsComponentsWrapperDesign3 = "x_l0 d_l0 d_x d_v d_by d_bL d_ct d_dz";
export var colEqualHeights = "x_sN d_by";
export var productsImageWrapperLeft = "x_lB d_lB d_v d_Y d_bw";
export var productsImageWrapperDesign2 = "x_lC d_lC d_v";
export var design2TextWrapper = "x_sP d_q d_bB d_bN d_bH";
export var exceptionWeight = "x_rV z_ts";